
$top-bar-height: 80px;
$topbar-padding: 0;

$small-gutter: 20;
$medium-gutter: 16;
$large-gutter: $medium-gutter;
$xlarge-gutter: $medium-gutter;


$main-padding-top: 25px;
$main-padding-bottom: 35px;
$main-padding-left: 10px;
$main-padding-right: 10px;

/* Mixin */
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin line-separator($width: 20%){
  height:1px;
  width: $width;
  background: #cbcbcb;
  margin-bottom: 1em;
}