@charset 'utf-8';
@import 'variables';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include foundation-global-styles;
// @include foundation-grid;
//@include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
// @include foundation-float-classes;
@include foundation-flex-classes;
// @include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;
body,
h4,
h5,
h6 {
    font-family: didact gothic, Arial, Helvetica;
    color: $dark-gray;

}

h1,
h2,
h3 {
    font-family: 'Unna', serif;
}

h1 {
    line-height: 3.7rem;
}

h3 {
    color: $white;
}

h6 {
    font-weight: bold;
}

a {
    color: $ocean-blue;
    font-weight: bold;
    &:hover {
        text-decoration: underline;
    }
}

.menu {
    margin: 40px 0 0 30px;
    a {
        color: $white;
        padding: 20px 0;
    }
}

.link-blogue:after {
    content: "";
    border-left: #fff 1px solid;
    padding-left: 20px;
    background-color: #fff
}

.blog-button {
    background-color: #390754;
    padding: 0.5em;
    color: $white;
    img {
        width: 0.8em;
        margin-left: 0.5em;
        vertical-align: 0em;
    }
}

.header {
    height: 40px;
    background-image: linear-gradient( 50deg, #8bf2e9 16%, #9c62bb 79%);

    a {
        color: #fff;
    }

        .top-bar {
            height: $top-bar-height;
            color: $white;
            letter-spacing: 0.1em;
            margin-top: -42px;
            padding-top: 45px;

            @include breakpoint (large up) {
                margin-top: 0;
            }
            .name {
                font-family: 'Unna', serif;
                font-size: 24px;
                letter-spacing: 0.1em;
            }
        }

        .main-menu {
            ul {
                list-style-type: none;
                margin: 0;
                padding: 40px;
                overflow: hidden;
                font-weight: bold;
            }
            li {
                float: left;
                padding-left: 40px;
            }
            li a {
                display: block;
                padding: 20px;
            }
        }
    
}

.intro-outer {
    background-image: linear-gradient( 50deg, #8bf2e9 16%, #9c62bb 79%);
    height: 500px;
    margin-bottom: -100px;
    margin-top: -40px;
    padding-top: 9em;

    @include breakpoint (medium up) {
        height: 800px;
    }             
}

.slogan {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 20px;

    @include breakpoint (medium) {
        width: 40%;
        margin-top: 80px;

    }

    @include breakpoint (large) {
        width: 40%;
        margin-top: 100px;

    }

 
}

.scroll-arrow{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    margin-top: 80px;
    color: $white;

    @include breakpoint (medium) {
        width: 40%;
        margin-top: 200px;

    }

    @include breakpoint (large) {
        width: 40%;
        margin-top: 100px;

    }
}


#outer {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 20%;
    z-index: 1;
}

#a-vecteur {
    position: absolute;
    top: 20px;
    right: 20px;
    img {
        width: 300px;
    }
}

.line-separator {
    @include line-separator;
}

.boites {
    background-color: $white;
    color: $dark-gray;
    height: 42em;
    margin-bottom: 40px;
    h2 {
        margin-bottom: 40px;
        @include breakpoint (medium up) {
            margin-bottom: 80px;
        }
    }
    .line-separator {
        margin-top: 3em;
    }
    #design {
        background-image: url("../../assets/img/ic_pen@2x.svg");
        background-position: 1em 33em;
        background-size: 160px;
        background-repeat: no-repeat;
        padding: 40px;
        height: inherit;
    }
    #prototype {
        background-image: url("../../assets/img/ic_prototypes@2x.svg");
        background-position: 1em 33em;
        background-size: 180px;
        background-repeat: no-repeat;
        padding: 40px;
        height: inherit;
        h2 {
            @include breakpoint (xlarge only) {
                margin-bottom: 20px;
            }
        }
    }
    #prog {
        background-image: url("../../assets/img/ic_code_coul.svg");
        background-position: 1em 33em;
        background-size: 280px;
        background-repeat: no-repeat;
        padding: 40px;
        height: inherit;
    }
}

.square-grid {
    &>div {
        position: relative;
        margin-top: 2.5em;
        &:before {
            display: table;
            padding-top: 100%;
            content: '';
        }
    }
    .content-grid {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: left;
        align-items: left;
        background-color: #fff;
        opacity: 0.8;
        padding: 4em 1em 1em 1.5em;
    }
}

.section-title {
    margin-top: 2.5em;
}

.services-small {
    background-size: cover;
    background-image: url("../../assets/img/pic_road2.jpg");
    background-repeat: no-repeat;
    padding: 0 1.5em 1.5em 1.5em;
    height: 100%;
    font-weight: bold;

    li{
        margin: 20px;
        padding: 40px;
        background-color: #fff;
        opacity: 0.8;
        list-style: none;
        text-align: center;
    }
}
.services {
    background-size: cover;
    background-image: url("../../assets/img/pic_road2.jpg");
    background-repeat: no-repeat;
    padding: 0 1.5em 1.5em 1.5em;
    height: 100%;
    font-weight: bold;
}

.reflexion {
    margin: 7em 0;
    h2,
    p {
        text-align: center;
    }
    #pic_circle {
        clip-path: circle(120px at center);
        height: 240xpx;
        margin-right: auto;
        margin-left: auto;
        display: block;
        @include breakpoint (medium up) {
            clip-path: circle(140px at center);
            height: 300px;
            margin-right: auto;
            margin-left: auto;
            display: block;
        }
    }
}

.portfolio {
    background-color: #fff;
    padding: 2em;

    .square-grid {

        &>div {
            padding: 2px;
            margin-top: 0em;
            &:before {
                padding-top: 0;
            }
            &>div {
                margin-top: 0;
                position: relative;
                /*
                &:after {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    background: rgba(0, 0, 0, .5);
                    -moz-transition: all 1s;
                    -webkit-transition: all 1s;
                    transition: all 1s;
                    top: 0;
                    left: 0;
                    position: absolute;
                }
                &:hover:after {
                    opacity: 1;
                }*/
            }
        }
    }
}

.clients {
    padding-top: 4em;

    @include breakpoint (medium up) {
        padding-top: 7em;

    }


    h2 {
        margin: auto;
        text-align: center;
    }
    .gallery {
        @include xy-grid;
        @include xy-grid-layout(1,'.gallery-item');


        .gallery-item {

            &:first-child { 
                height: 30px;
            }

            opacity: 0.5;
            height: 60px;
            margin: 42px 0;
        }
        @include breakpoint (medium up) {
            @include xy-grid;
            @include xy-grid-layout(3,'.gallery-item');
            margin: 70px 0;
            align-content: center;

            .gallery-item {
                padding: 2em;
                height: 38% !important;
                margin: 0 10px;
            }
            
            
        }
        @include breakpoint (large up) {
            @include xy-grid;
            @include xy-grid-layout(5,'.gallery-item');
            margin: 120px 0;

            .gallery-item {
                padding: 2em;
                height: 75% !important;
                margin: 0 10px;
            }
        }
    }
}

.cloud {
    background-size: cover;
    background-color: #000;
    background-repeat: no-repeat;
    padding: 1.5em 1.5em 1.5em 1.5em;
    font-weight: bold;
    .square-grid {
        @include breakpoint (large up) {
            &>div {
                margin-top: 0em;
            }
        }
    }
    .section-title {
        @include vertical-align;
        margin-top: 0;
    }
}

.intro-pages-outer {
    background-image: linear-gradient( 50deg, #8bf2e9 16%, #9c62bb 79%);
    height: 350px;
    margin-top: -40px;
    .intro-pages {
        padding-top: 4em;
        img {
            width: 500px;
        }
        h3 {
            margin-top: 1.5em;
        }
    }
}

.project {

    margin-top: 4em;

    @include breakpoint (1024 up) {
        margin-top: 10em;
    }

    .img-project {
        max-height: 400px;
    
    }
    .voir {
        width: 25px;
        margin-right: 5px;
        padding-bottom: 3px;
    }
    h4 {
        text-transform: uppercase;
        font-weight: bold;
    }
    h5 {
        margin-bottom: 2em;
    }

    .link-hover{

        color: $dark-gray;

        &:hover{
            color: $ocean-blue;
        }
    }

}

#page-projet {
    .project-detail {
        background-color: $white;
        padding-top: 8em;
    }
    h5+p {
        margin-top: 3em;
    }

    h4{
        font-weight: bold;
        text-transform: uppercase;
    }
    .img-container {
        margin: 4em 0;
        img {
            max-height: 500px;
            margin-bottom: 2em;
        }
    }
    .back {
        padding: 2em 0 1em 0;
        img {
            width: 30px;
            margin-right: 5px;
        }
    }
}

#page-services {
    .service-detail {
        margin: 10em 0 4em 0;
    }
    .box-services {
        
        &>div {
            background-color: $white;
            height: 100%;
            padding: 3em;
            margin-top: 2em;

            @include breakpoint(large up){
                min-height: 320px;
            }
        }

        p{
            margin-bottom: 0px;
        }
    }
    h2 {
        padding-bottom: 1.5em;
    }
    .line-separator {
        @include line-separator (10%);
    }
    .exemples {
        span {
            font-weight: bold;
        }
        div {
            margin-bottom: 2em;
        }
    }
}

#page-apropos {
    margin-bottom: 10em;
    
    .overlay {
        position: relative;
        display: inline-block;
        background-image: url("../../assets/img/pic_joseetremblay2.jpg");
        background-size: 400%;
        background-repeat: no-repeat;
        background-position-x: -30em;
        height: 408px;
        margin: 3em 0;
        @include breakpoint (medium) {
            background-size: 210%;
            background-position-x: -165px;
        }
        @include breakpoint (large up) {
            height: 500px;
            background-size: 180%;
            background-position-x: 0px;
        }
    }
    .overlay:before {
        display: block;
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
    }
    .txt {
        @include vertical-align;
        color: #fff;
        font-size: 1.2em;
        text-align: center;
        padding: 2em;
        span {
            font-size: 1.8em;
            font-family: 'Unna', serif;
            letter-spacing: 0.2em;
        }
        @include breakpoint (medium up) {
            @include vertical-align;
            color: #fff;
            font-size: 1.5em;
            text-align: center;
            span {
                font-size: 2em;
                font-family: 'Unna', serif;
                letter-spacing: 0.2em;
            }
        }
    }
    .introduction {
        padding-right: 0;

        @include breakpoint (large up){ 
            padding-right: 10em;
        }

        margin-bottom: 2em;
        
    }
    .experience {
        span {
            font-weight: bold;
        }
    }
}

#page-portfolio {
    margin-bottom: 10em;
}

.realisations{
    position: relative;

    p{
        position: absolute;
        top: 47%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1;
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0;
        white-space: nowrap;

        @include breakpoint(medium up){
            font-size: 1.5rem;
        }
    }
}

.orbit-text{
    font-size: 2rem;
    position: absolute;
    top: 56%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    @include breakpoint(medium){
        font-size: 3.2rem;
    }

    @include breakpoint(large up){
        font-size: 4rem;
    }
}

.orbit-container{
    height: 500px;

    @include breakpoint(medium){
        height: 500px;
    }

    @include breakpoint(large up){
        height: 600px;
    }

    .orbit-image{
        height: 500px;
        object-fit: cover;
    
        @include breakpoint(medium){
            height: 500px;
        }

        @include breakpoint(large up){
            height: 600px;
        }
    }
}

.close-button{
    color: $dark-gray;
}



.footer {
    background-color: #d2d6db;
    padding: 5em 0;
    color: $dark-gray;
    a {
        color: $dark-gray;
    }
    .name {
        font-family: 'Unna', serif;
        font-size: 24px;
        letter-spacing: 0.1em;
    }
    #slogan-footer {
        width: 200px;
        color: #000;
        margin-top: 20px;
    }
    .email {
        font-weight: bold;
        margin-top: 50px;
        @include breakpoint (large up) {
            margin-top: 0;
        }
    }
    .menu-footer {
        margin-top: 50px;
        @include breakpoint (large up) {
            margin-top: 0;
        }
        ul {
            list-style-type: none;
            overflow: hidden;
            font-weight: bold;
            margin-left: 0;
            li {
                padding: 8px 0;
            }
        }
    }
}




/*

.parallax {
    perspective: 1px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .parallax__layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

  }
  .parallax__layer--base {
    transform: translateZ(0);
  }
  .parallax__layer--back {
    position: relative;
    transform: translateZ(-1px) scale(2);
    background-size: cover;
    background-image: url("../../assets/img/pic_road2.jpg");
    background-repeat: no-repeat;
    height: 1000px;
    background-attachment: fixed;
}

.MainContainer {
position: relative;
overflow: hidden;
z-index: 10;

  }

  
  .ParallaxContainer {

    position: absolute;
    z-index: 1;
    height: 100vh;
    justify-content: center;
    align-items: center;
    transform: translate3d(0px, -274.265px, 0px);
    background: url("../../assets/img/pic_road2.jpg");

  }

  p {
    width: 100%;
    margin: auto;
    font-size: 50px;
    transform: scale(.2);
    font-family: courier new;
    font-weght: bold;
  }
  
  div {
    background-image: url("http://www.hdwallpapers.net/images/triangular-grads-wallpaper-for-1600x1200-72-701.jpg");
    background-attachment: fixed;
    transform: scale(1.2);  
    background-repeat: no-repeat;
  }
  */